import React from 'react';

function ProblemStatementSection() {
    return (
        <section className="section problemstatement dark">
            <h2 className="light section-headline right">Problem Statement</h2>

            <section className="problemstatement-container">
                <h4 className="light">“People who want to monetize their luxury goods need to find a way to <span className="highlighted">seamlessly</span> use application features to confirm authenticity because their lack of <span className="highlighted">knowledge</span> & <span className="highlighted">trust</span> in innovative technology.”
                </h4>
            </section>
        </section>
    );
}

export default ProblemStatementSection;
