import React from 'react';

function QuantitativeSection() {
    return (
        <section className="section quantitative">
            <h2 className="light section-headline">Quantitative Research</h2>

            <section className="quantitative-container">
                <section className="container">
                    <section className="container quantitative-content">
                        <h4 className="medium subtitle">
                            To gain deeper insights into users' evening <span className="highlighted">habits and challenges</span> with their sleep-quality, we collected quantitative data through a Google Forms survey.
                        </h4>

                        <section className="survey">
                            <h5 className="light responses">We had 84 responses:</h5>
                            <div className="card">
                                <p className="percentage highlighted">42%</p>
                                <p className="p1-light">are willing to try methods to enhance the quality of their sleep</p>
                            </div>
                            
                            <div className="card">
                                <p className="percentage highlighted">43%</p>
                                <p className="p1-light">say that physical condition affects the quality of their sleep</p>
                            </div>
                            
                            <div className="card">
                                <p className="percentage highlighted">41%</p>
                                <p className="p1-light">have difficulties sticking to a routine</p>
                            </div>
                            
                            <div className="card">
                                <p className="percentage highlighted">66%</p>
                                <p className="p1-light">say their emotional state affects the quality of their sleep</p>
                            </div>
                        </section>

                    </section>
                </section>
            </section>
        </section>
    );
}

export default QuantitativeSection;
