import React from 'react';
import concept1 from '../images/concept_1.png'
import concept2 from '../images/concept_2.png'
import concept3 from '../images/concept_3.png'

function ConceptTestsSection() {
    return (
        <section className="section concept-content">
            <h2 className="light section-headline">Concept Tests</h2>

            <section className="container concept-container">
                <h4 className="medium subtitle">Using Lo-Fi Wireframes we performed the first testings to validate the effectiveness of our concept for digital twin and authentication process.</h4>

                <section className="concept-test">
                    <section className="dividing">
                        <p className="p1-light concept-text">
                            This step is confusing: What exactly is meant by photo accuracy?
                        </p>
                        <img src={concept1} alt="Mobile app interface for setting work hours with weekday options and customizable time slots."></img>
                    </section>

                    <section className="personalized">
                        <p className="p1-light concept-text">
                            Provide options to
                            Authenticate Item via App,
                            Download digital twin and
                            Authenticate Item via Receipt.
                        </p>
                        <img src={concept2} alt="Evening routine planner in a health app displaying the calendar date, scheduled activities like Exercise and Meditation, and a button to add new entries."></img>
                    </section>

                    <section className="dividing">
                        <p className="p1-light concept-text">
                            Convey material details while scanning to show trustworthy expertise.
                        </p>
                        <img src={concept3} alt="Evening routine planner in a health app displaying the calendar date, scheduled activities like Exercise and Meditation, and a button to add new entries."></img>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default ConceptTestsSection;
