import retrovrsImg from '../images/retrovrs.png';
import ritualzzImg from '../images/ritualzz.png';
import ikeaImg from '../images/ikea.png';
import lesculotteesImg from '../images/lesculottees.png';
// import uxImg from '../images/ux.png';
import taskiImg from '../images/taski.png';
import quoteGeneratorImg from '../images/quote-generator.png';
import webImg from '../images/web.jpg';

const projectsData = {
    all: [],
    'ux-ui': [
        {
            imgUrl: retrovrsImg,
            alt: "Project 1 Retro-VRS",
            description: "Retro//VRS",
            label: "ux/ui",
            cat1: "iOS Mobile Design",
            cat2: "Feature Design",
            cat3: "Luxury Asset Hub",
            href: "/casestudies/retrovrs"
        },
        {
            imgUrl: ritualzzImg,
            alt: "Project 2 Ritualzz",
            description: "Ritualzz",
            label: "ux/ui",
            cat1: "iOS Mobile Design",
            cat2: "MVP",
            cat3: "Wellness Market",
            href: "/casestudies/ritualzz"
        },
        {
            imgUrl: ikeaImg,
            alt: "Project 3 IKEA",
            description: "IKEA",
            label: "ux/ui",
            cat1: "iOS Mobile Design",
            cat2: "Redesign",
            cat3: "E-Commerce",
            href: "https://medium.com/@bellikal/ikea-redesigning-an-app-fa429790b3dc"
        },
        {
            imgUrl: lesculotteesImg,
            alt: "Project 4 Les Culottèes",
            description: "Les Culottèes",
            label: "ux/ui",
            cat1: "Responsive Design",
            cat2: "Redesign",
            cat3: "E-Commerce",
            href: "https://medium.com/@bellikal/les-culott%C3%A9es-de-saturne-responsive-e-commerce-website-e54618110db4"
         },
        // {
        //     imgUrl: uxImg,
        //     alt: "UX/UI Projekt 5",
        //     description: "UX/UI Bildbeschreibung 5",
        //     label: "ux/ui",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt5"
        // },
        // {
        //     imgUrl: uxImg,
        //     alt: "UX/UI Projekt 6",
        //     description: "UX/UI Bildbeschreibung 6",
        //     label: "ux/ui",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt6"
        // } // Weitere Projekte hinzufügen
    ],
    'web-development': [
        {
            imgUrl: taskiImg,
            alt: "Ipad with Taskmanagement Web-Application 'Taski'",
            description: "Taski",
            label: "dev",
            cat1: "CRUD Project",
            cat2: "Laravel",
            cat3: "MySQL",
            href: "/casestudies/taski"
        },
        {
            imgUrl: quoteGeneratorImg,
            alt: "Laptop with Quote-Generator Web Application",
            description: "Quote-Generator",
            label: "dev",
            cat1: "JavaScript",
            cat2: "Fetch API",
            cat3: "asynch / await",
            href: "https://bellikal.github.io/quote-generator/"
        },
        // {
        //     imgUrl: webImg,
        //     alt: "Web Projekt 3",
        //     description: "Web Bildbeschreibung 3",
        //     label: "dev",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt3"
        // },
        // {
        //     imgUrl: webImg,
        //     alt: "Web Projekt 4",
        //     description: "Web Bildbeschreibung 4",
        //     label: "dev",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt4"
        // },
        // {
        //     imgUrl: webImg,
        //     alt: "Web Projekt 5",
        //     description: "Web Bildbeschreibung 5",
        //     label: "dev",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt5"
        // },
        // {
        //     imgUrl: webImg,
        //     alt: "Web Projekt 6",
        //     description: "Web Bildbeschreibung 6",
        //     label: "dev",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt6"
        // },
        // {
        //     imgUrl: webImg,
        //     alt: "Web Projekt 7",
        //     description: "Web Bildbeschreibung 7",
        //     label: "dev",
        //     cat1: "iOS",
        //     cat2: "Feature Design",
        //     cat3: "Luxury Asset Hub",
        //     href: "#projekt7"
        // } // Weitere Projekte hinzufügen
    ]
  };
  
  export default projectsData;
  