import React from 'react';

function PrototypeSection() {
    return (
        <section className="section prototype-content">
            <h2 className="light section-headline">Final Product</h2>

            <section className="container prototype-container">
                <section className="prototype-video">
                    <iframe width="900px" height="600px" src="https://www.youtube.com/embed/p1BG8ZiHh9A?si=H3BStyUydVm_D9W6"
                        title="YouTube video player" frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                    </iframe>
                </section>
            </section>
            <p className="p1-light github taski">
                For the full code of this application, visit my GitHub repository:
            </p>
            <a className="secondary medium button" href="https://github.com/bellikal/taski" target="_blank" rel="noopener noreferrer">Source Code on GitHub</a>


        </section>
    );
}

export default PrototypeSection;
