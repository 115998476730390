import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useActiveSection from './useActiveSection';
import useHideOnScroll from '../hooks/useHideOnScroll';
import Aside from './Aside';
import '../css/Header.css';

function Header() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();
    const isCaseStudyPage = location.pathname.includes('/casestudies');
    const { isNavVisible, isScrollUpVisible } = useHideOnScroll(isCaseStudyPage);
    const sections = ['home', 'work', 'about', 'contact'];
    const activeSection = useActiveSection(sections);
    const navigate = useNavigate();

    // Diese Funktion navigiert zur Startseite und scrollt zum entsprechenden Abschnitt
    const navigateToSection = (sectionId) => {
        navigate('/');
        setTimeout(() => {
            const section = document.getElementById(sectionId);
            if (section) {
                section.scrollIntoView({ behavior: 'smooth' });
            }
            // Menü schließen, wenn ein Menüpunkt angeklickt wird
            setIsMenuOpen(false);
        }, 0);
    };

    const handleLogoClick = () => {
        // Überprüft, ob die aktuelle Route der Home-Bereich ist
        if (location.pathname === "/") {
            // Scrollt zum Anfang der Seite, wenn bereits auf Home
            window.scrollTo({ top: 0, behavior: 'smooth' });
        } else {
            // Navigiert zur Home-Seite, wenn nicht auf Home
            navigate('/');
        }
        // Menü schließen, wenn geöffnet
        setIsMenuOpen(false);
    };

    // Anpassung der navClass, um sie basierend auf isNavVisible und isCaseStudyPage zu setzen
    let navClass = `main-nav ${isMenuOpen ? 'open' : ''} ${isNavVisible ? 'nav-visible' : 'nav-hidden'}`;

    return (
        <>
            <div className={`menu-background ${isMenuOpen ? 'active' : ''}`}></div>

            <header>
                <button className="logo" onClick={handleLogoClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 40 40" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 21.0278V0.0699759C0.534732 0.026241 1.08699 0 1.63049 0C12.0095 0 20.3636 8.52832 20.3636 18.9722V39.93C19.8289 39.9738 19.2766 40 18.7331 40C8.36285 40 0 31.4717 0 21.0278ZM18.7331 38.5455C18.7916 38.5455 18.8503 38.5451 18.9091 38.5445V18.9722C18.9091 9.3108 11.1855 1.45455 1.63049 1.45455C1.57203 1.45455 1.51337 1.45488 1.45455 1.45553V21.0278C1.45455 30.6888 9.18642 38.5455 18.7331 38.5455Z" fill="#EE2E46" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.7273 18.1336V40C25.2197 39.8451 25.7048 39.6727 26.1818 39.4833C34.238 36.286 40 28.2764 40 18.9555V18.022C40 14.2474 36.798 10.9091 32.697 10.9091C28.596 10.9091 24.7273 14.2474 24.7273 18.1336ZM32.697 12.3636C31.0329 12.3636 29.3786 13.0466 28.1426 14.1493C26.9063 15.2524 26.1818 16.6857 26.1818 18.1336V37.9092C33.4114 34.791 38.5455 27.4618 38.5455 18.9555V18.022C38.5455 15.0578 36.0017 12.3636 32.697 12.3636Z" fill="#EE2E46" />
                    </svg>
                </button>


                <div className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)}>
                    {isMenuOpen ? (
                        // X-Icon SVG
                        <svg className="x-icon" width="40" height="40" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 6L6 18" stroke="#EE2E46" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M6 6L18 18" stroke="#EE2E46" stroke-width="1" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    ) : (
                        // Hamburger-Icon SVG
                        <svg className="hamburger" width="30" height="40" viewBox="0 0 100 80" fill="#EE2E46" xmlns="http://www.w3.org/2000/svg">
                            <rect width="100" height="5"></rect>
                            <rect y="30" width="100" height="5"></rect>
                            <rect y="60" width="100" height="5"></rect>
                        </svg>
                    )}
                </div>

                <nav className={navClass}>
                    <ul className="nav-ul">
                        {sections.map((section) => (
                            <li key={section} className={activeSection === section ? 'active' : ''}>
                                <a onClick={() => navigateToSection(section)} href={`#${section}`}>
                                    {section.charAt(0).toUpperCase() + section.slice(1)}
                                </a>
                            </li>
                        ))}
                    </ul>

                    {isMenuOpen && <Aside />}
                </nav>

                <button
                    className={`scroll-up-button ${isScrollUpVisible ? 'show' : ''}`}
                    onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
                >
                    <p class="arrow-up">↑</p>
                </button>

                <a id="cv-header" href={`${process.env.PUBLIC_URL}/cv.pdf`} className="secondary medium button" target="_blank" rel="noopener noreferrer">CV</a>
            </header>
        </>
    );
}

export default Header;