import React from 'react';
import persona from '../images/persona.png';

function PersonaSection() {
    return (
        <section className="section persona dark">
            <h2 className="light section-headline right">User Persona</h2>

            <section className="persona-container">
                <section className="persona-content">


                    <section className="container">
                        <section className="persona-show">
                            <img src={persona} className="persona-img" alt="Portrait of a smiling young woman with a messy bun"></img>
                            <h4 className="light">"Why do I have to learn
                                rocket science to sell my bag?!"
                            </h4>
                        </section>

                        <section className="persona-infos">
                            <section className="persona-discription">
                                <h4 className="medium">FASHIONISTA FIONA</h4>
                                <p className="p1-light">
                                    A passionate luxury item collector, aims to monetize her assets, ensure their authenticity, and protect others from purchasing counterfeits, despite her limited tech-savviness.
                                </p>
                            </section>

                            <section className="needs-frustrations">
                                <section className="needs">
                                    <h4 className="medium">Needs</h4>
                                    <ul>
                                        <li><p className="p1-light">Find a way to monetize luxury items</p></li>
                                        <li><p className="p1-light">Ensure that luxury items are authentic</p></li>
                                        <li><p className="p1-light">Help others to avoid purchasing counterfeits</p></li>
                                    </ul>
                                </section>

                                <section className="frustrations">
                                    <h4 className="medium">Frustrations</h4>
                                    <ul>
                                        <li><p className="p1-light">Dislikes the idea of buying or selling counterfeits</p></li>
                                        <li><p className="p1-light">Finds the authenticating process of luxury assets too time consuming</p></li>
                                        <li><p className="p1-light">Has difficulties to navigate through the digital world</p></li>
                                        <li><p className="p1-light">Lacks trust in innovative authentication solutions</p></li>
                                    </ul>

                                </section>
                            </section>
                        </section>
                    </section>


                </section>


            </section>
        </section>
    );
}

export default PersonaSection;
