import { useEffect, useState } from 'react';

const useActiveSection = (sections) => {
  const [activeSection, setActiveSection] = useState('');

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
          }
        });
      },
      { rootMargin: '0px', threshold: 0.5 }
    );

    sections.forEach((section) => {
      const sectionEl = document.getElementById(section);
      if (sectionEl) {
        observer.observe(sectionEl);
      }
    });

    return () => {
      sections.forEach((section) => {
        const sectionEl = document.getElementById(section);
        if (sectionEl) {
          observer.unobserve(sectionEl);
        }
      });
    };
  }, [sections]);

  return activeSection;
};

export default useActiveSection;
