import React, { useEffect, useRef } from 'react';

const Project = ({ project }) => {
    const projectRef = useRef(null);

    useEffect(() => {
        const projectElement = projectRef.current; // Speichere den aktuellen Wert von projectRef in einer Variablen
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    entry.target.querySelector('figcaption').classList.add('is-visible');
                } else {
                    entry.target.querySelector('figcaption').classList.remove('is-visible');
                }
            },
            {
                threshold: 1 // Trigger when 10% of the item is visible
            }
        );

        if (projectElement) {
            observer.observe(projectElement);
        }

        return () => {
            if (projectElement) {
                observer.disconnect(); // Verwende die gespeicherte Variable im Cleanup
            }
        };
    }, []); // Leeres Abhängigkeitsarray bedeutet, dass dieser Effekt nur beim Mounten und Unmounten läuft

    return (
        <article className={project.projectClass} ref={projectRef}>
            <figure>
                <a className="project-link" href={project.href} target="_blank" rel="noopener noreferrer">
                    <img className="project-image" src={project.imgUrl} alt={project.alt} />
                    <figcaption className="project-caption">
                        <div className="description-container">
                            <p className="project-description">{project.description}</p>
                            <p className="project-label">{project.label}</p>
                            <p className="project-categories">{project.cat1}</p>
                            {project.cat2 && <p className="project-categories">{project.cat2}</p>}
                            {project.cat3 && <p className="project-categories">{project.cat3}</p>}
                        </div>
                    </figcaption>
                </a>
            </figure>
        </article>
    );
};

export default Project;
