import React from 'react';

function ResearchSection() {
    return (
        <section className="section research">
            <h2 className="light section-headline right">Research</h2>

            <section className="research-container">
                <section className="container">
                    <section className="container research-content">
                        <h4 className="medium right subtitle">
                            To gain insights into the factors that impact sleep quality,
                            we conducted initial research:
                        </h4>

                        <section className="container">
                            <section className="facts">
                                <h5 className="medium">Factors Affecting Sleep Quality:</h5>

                                <ul>
                                    <li><p className="p1-light">Irregular Sleep Schedules</p></li>
                                    <li><p className="p1-light">Sleep Disorders</p></li>
                                    <li><p className="p1-light">Mental Health</p></li>
                                    <li><p className="p1-light">Dietary Impact</p></li>
                                </ul>
                            </section>

                            <section className="research-text">
                                <p className="p1-light">
                                    Quality sleep is crucial for overall well-being and productivity.
                                </p>
                                <p className="p1-light">
                                    It supports physical recovery, cognitive function, and mental health. Restful sleep contributes to tissue repair, muscle growth, and hormone regulation.
                                    Additionally, it enhances focus, decision-making, and reduces the risk of chronic health conditions.
                                </p>
                                <p className="p1-light">
                                    To achieve quality sleep, consistent habits and a conducive sleep environment are essential. Prioritizing restorative sleep empowers individuals to lead healthier, more energetic, and productive lives.
                                </p>
                            </section>
                        </section>
                    </section>
                </section>

                <section className="container middle">
                    <h3 className="light">
                        So why do people <span className="highlighted">need</span> a sleep quality app?
                    </h3>
                </section>

                <section className="research-text">
                    <p className="p1-light">
                        Sleep quality apps can be crucial as they offer various functions that can assist people in <span className="highlighted">improving their sleep quality</span>.
                    </p>
                    <p className="p1-light">
                        They often provide valuable insights, facilitate goal-setting, grant access to sleep aids, and support the development of healthier routines, which is especially beneficial as many individuals struggle to find time for extensive research or even to adhere to a consistent routine.
                    </p>
                </section>

            </section>
        </section>
    );
}

export default ResearchSection;
