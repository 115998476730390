import React from 'react';
import '../css/Contact.css';

function Contact() {
    return (
        <section id="contact" className="section">
            <h1 className="bold tagline">Sounds like a mindset you resonate with?</h1>

            <section className="connect">
                <a href="mailto:hi@bellikal.de">
                    <button className="secondary big">Let's Connect</button>
                </a>
            </section>
        </section>
    );
}
export default Contact;
