import React from 'react';
import entrupy from '../images/vca_entrupy.png'
import authentifier from '../images/vca_authentifier.png'
import dataweave from '../images/vca_dataweave.png'

function VCASection() {
    return (
        <section className="section vca-content">
            <h2 className="light section-headline">Visual Competitor Analysis</h2>

            <section className="container vca-container">
                <h4 className="medium subtitle">Starting our Visual Analysis, we specifically focused on authentication market competitors due to the significant emphasis on trust throughout our entire process.</h4>

                <section className="vca-tiles">

                    <img src={entrupy} alt=""></img>

                    <img src={authentifier} alt=""></img>

                    <img src={dataweave} alt=""></img>

                </section>
            </section>
        </section>
    );
}

export default VCASection;
