import React from 'react';
import '../../../css/Casestudy.css'

import TitleSection from './components/TitleSection';
import IntroSection from './components/IntroSection';
import ResearchSection from './components/ResearchSection';
import CompetitiveSection from './components/CompetitiveSection';
import QuantitativeSection from './components/QuantitativeSection';
import QualitativeSection from './components/QualitativeSection';
import EmpathyMapSection from './components/EmpathyMapSection';
import PersonaSection from './components/PersonaSection';
import UserJourneyMapSection from './components/UserJourneyMapSection';
import ProblemStatementSection from './components/ProblemStatementSection';
import MoscowMethodSection from './components/MoscowMethodSection';
import MVPStatementSection from './components/MVPStatementSection';
import ConceptTestsSection from './components/ConceptTestsSection';
import UsabilityTestsSection from './components/UsabilityTestsSection';
import VCASection from './components/VCASection';
import MoodStyleSection from './components/MoodStyleSection';
import PrototypeSection from './components/PrototypeSection';


function Ritualzz() {
  return (
    <article id="ritualzz" className="casestudy">
      <TitleSection />
      <IntroSection />
      <ResearchSection />
      <CompetitiveSection />
      <QuantitativeSection />
      <QualitativeSection />
      <EmpathyMapSection />
      <PersonaSection />
      <UserJourneyMapSection />
      <ProblemStatementSection />
      <MoscowMethodSection />
      <MVPStatementSection />
      <ConceptTestsSection />
      <UsabilityTestsSection />
      <VCASection />
      <MoodStyleSection />
      <PrototypeSection />
    </article>
  );
}

export default Ritualzz;

