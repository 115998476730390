import { useState, useEffect, useRef } from 'react';

const useHideOnScroll = (shouldHide) => {
  const [visible, setVisible] = useState(true);
  const [scrollUpVisible, setScrollUpVisible] = useState(false);
  const lastScrollY = useRef(0);

  useEffect(() => {
    if (!shouldHide) {
      setVisible(true);
      setScrollUpVisible(false);
      return;
    }

    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;

      // Sichtbarkeit der Navigation steuern
      setVisible(currentScrollPos <= lastScrollY.current || currentScrollPos <= 50);

      // Sichtbarkeit des Scroll-Up-Buttons steuern (z.B. ab 100px Scroll-Distanz)
      setScrollUpVisible(currentScrollPos > 100);

      lastScrollY.current = currentScrollPos;
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [shouldHide]);

  // Gib beide Zustände (für die Navigation und den Scroll-Up-Button) zurück
  return { isNavVisible: visible, isScrollUpVisible: scrollUpVisible };
};

export default useHideOnScroll;
