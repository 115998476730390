import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Components
import Header from './components/Header';
import Aside from './components/Aside';
import Hero from './components/Hero';
import Work from './components/Work';
import Profession from './components/Profession';
import About from './components/About';
import Skills from './components/Skills';
import Tools from './components/Tools';
import Contact from './components/Contact';
import Footer from './components/Footer';

// Pages
import Impressum from './pages/Impressum';
import Ritualzz from './pages/CaseStudies/Ritualzz/index';
import Retrovrs from './pages/CaseStudies/Retrovrs/index';
import Ikea from './pages/CaseStudies/Ikea/index';
import Taski from './pages/CaseStudies/Taski/index';

// Global Styles
import './css/Fonts.css';
import './css/Typography.css';
import './css/Variables.css';
import './css/Components.css';
import './css/Responsive.css';


function App() {
  console.log('%cWelcome to my Portfolio! - Feel free to peek behind the curtain and discover my projects.', 'font-family: "Arial", sans-serif; color: #EE2E46; font-size: 16px; background-color: black; padding: 24px; border-radius: 5px;');

  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Aside />
              <Hero />
              <Work />
              <Profession />
              <About />
              <Skills />
              <Tools />
              <Contact />
            </>
          } />
          <Route path="/impressum" element={<Impressum />} />
          <Route path="/casestudies/ritualzz" element={<Ritualzz />} />
          <Route path="/casestudies/retrovrs" element={<Retrovrs />} />
          <Route path="/casestudies/ikea" element={<Ikea />} />
          <Route path="/casestudies/taski" element={<Taski />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
