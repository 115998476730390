import React from 'react';

function QuantitativeSection() {
    return (
        <section className="section qualitative dark">
            <h2 className="light section-headline right">Qualitative Research</h2>

            <section className="qualitative-container">
                <section className="qualitative-content">
                    <section className="subtitle-container">
                        <h4 className="medium right subtitle">
                            Now we want to take a look at people's <span className="highlighted">opinion</span> on preloved goods authentication, so we conducted some interviews:
                        </h4>
                    </section>

                    <section className="container middle">
                        <section className="qualitative-quotes">
                            <section className="quotes">
                                <p className="p1-light">
                                    "When it comes to high-value items, secure authentication is a must-have service."                                </p>
                                <p className="p1-light">- Tim</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "Accurate authentication and user-friendly experience are essential for me as a non-tech savvy buyer or seller."
                                </p>
                                <p className="p1-light">- Annika</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "Affordable and easy to use digital twin technology would make me seriously consider using such service!"
                                </p>
                                <p className="p1-light">- Hauke</p>
                            </section>
                        </section>
                    </section>


                </section>


            </section>
        </section>
    );
}

export default QuantitativeSection;
