import React from 'react';

const FilterButtons = ({ selectedCategory, setCategory }) => {
  const categories = ['all', 'ux-ui', 'web-development'];

  return (
    <div id="filters">
      {categories.map((category) => (
        <button
          key={category}
          className={`small filter ${selectedCategory === category ? 'active' : ''}`}
          onClick={() => setCategory(category)}
        >
          {category.charAt(0).toUpperCase() + category.slice(1)}
        </button>
      ))}
    </div>
  );
};

export default FilterButtons;
