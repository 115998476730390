import React from 'react';

function ResearchSection() {
    return (
        <section className="section research">
            <h2 className="light section-headline right">Lean UX Canvas</h2>

            <section className="research-container">
                <section className="container">
                    <section className="container research-content">
                        <h4 className="medium right subtitle">
                            Trust in Authenticity: Enhancing Preowned Luxury Goods Authentication & Protogrammetry Process
                        </h4>

                        <section className="container">
                            <div className="canvas-container">
                                <div className="canvas-section problem">
                                    <h4 className="light highlighted">Problem</h4>
                                    <hr />
                                    <p className="p2-light">Counterfeit items harm the luxury market, damaging brand reputations, causing financial losses, and benefiting illegal practices. This problem is particularly severe in the preowned sector, impacting customers and sellers alike. Building trust in the authenticity of preowned luxury items is challenging, leading to limited market penetration due to inadequate awareness of authentication processes among customers.</p>
                                </div>
                                <div className="canvas-section solution">
                                    <h4 className="light highlighted">Solution</h4>
                                    <hr />
                                    <p className="p2-light">Enhance the accuracy of the retro//fit feature by giving better guidance for the photogrammetry process.</p>
                                </div>
                                <div className="canvas-section targeted-audience">
                                    <h4 className="light highlighted">Targeted audience</h4>
                                    <hr />
                                    <p className="p2-light">Initially we should focus on customers who purchase or sell preowned luxury goods.</p>
                                    <p className="p2-light">Another option is to focus on businesses who are selling preowned luxury goods and try to verify the authenticity of them.</p>
                                </div>
                                <div className="canvas-section user-benefits">
                                    <h4 className="light highlighted">User Benefits</h4>
                                    <hr />
                                    <p className="p2-light">Users seek authenticity and trust when buying or selling preowned luxury goods. They desire a solution that ensures transparency and safeguards their investments in these assets, ensuring they receive what they paid for.</p>
                                </div>
                                <div className="canvas-section business-outcomes">
                                    <h4 className="light highlighted">Business Outcomes</h4>
                                    <hr />
                                    <p className="p2-light">More real scans of luxury assets.</p>
                                    <p className="p2-light">More sales and purchases.</p>
                                </div>
                                <div className="canvas-section hypotheses">
                                    <h4 className="light highlighted">Hypotheses</h4>
                                    <hr />
                                    <p className="p2-light">We believe that increased sales and trust in luxury goods authenticity can be achieved through an improved authentication and photogrammetry process.</p>
                                </div>
                                <div className="canvas-section most-important-thing">
                                    <h4 className="light highlighted">What's the most important thing we need to learn first?</h4>
                                    <hr />
                                    <p className="p2-light">The riskiest assumption is that customers will trust the authentication technology and believe that it accurately identifies counterfeit products.</p>
                                </div>
                                <div className="canvas-section least-work">
                                    <h4 className="light highlighted">What's the least amount of work we need to do to learn the next most important thing?</h4>
                                    <hr />
                                    <p className="p2-light">We can conduct concept & usability testings to get feedback on the understandability and trustworthiness of the Retro//Fit feature.</p>
                                </div>
                            </div>

                        </section>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default ResearchSection;
