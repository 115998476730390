import React from 'react';

function ProblemStatementSection() {
    return (
        <section className="section problemstatement dark">
            <h2 className="light section-headline right">Problem Statement</h2>

            <section className="problemstatement-container">
                <h4 className="light">“People who struggle to achieve restful sleep need help in improving their <span className="highlighted">evening routine</span> to reduce stress and fatigue levels.”</h4>
            </section>
        </section>
    );
}

export default ProblemStatementSection;
