import React from 'react';
import usability1 from '../images/usability_1.png'
import usability2 from '../images/usability_2.png'

function UsabilityTestsSection() {
    return (
        <section className="section usability-content dark">
            <h2 className="light section-headline right">Usability Tests</h2>

            <section className="container usability-container">
                <h4 className="medium subtitle right">Next we tested the <span className="highlighted">usability</span> on our Mid-Fi Wireframes.</h4>

                <section className="usability-test">
                    <section className="dividing">
                        <p className="p1-light usability-text">
                            Users would find filters valuable in the explore screen as they would offer an effortless means to address their specific preferences. <br />
                            By including filters such as categories, length, and speaker, we would ensure easier access to relevant information for the users, resulting in a more user-friendly experience.
                        </p>
                        <img src={usability1} alt="Mobile app's Explore screen displaying a user-friendly interface with category filters for Exercises, Meditations, Stories, Music, Noises, and Tips."></img>
                    </section>

                    <section className="personalized">
                        <p className="p1-light usability-text">
                            A daily progress screen in addition would enhance the user experience, enabling users to track their activities, stay motivated, and improve their evening routines and sleep quality effectively.
                        </p>
                        <img src={usability2} alt="Mobile app's Progress screen showing a graph of user's daily activity tracking and the ability to view details for motivation and routine improvement."></img>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default UsabilityTestsSection;
