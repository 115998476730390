import React from 'react';
import persona from '../images/persona.png';

function PersonaSection() {
    return (
        <section className="section persona dark">
            <h2 className="light section-headline right">User Persona</h2>

            <section className="persona-container">
                <section className="persona-content">


                    <section className="container">
                        <section className="persona-show">
                            <img src={persona} className="persona-img" alt="Portrait of a smiling young woman with a messy bun"></img>
                            <h4 className="light">“It is so hard for me to stick to a routine!”</h4>
                        </section>

                        <section className="persona-infos">
                            <section className="persona-discription">
                                <h4 className="medium">Unguided Ulrike</h4>
                                <p className="p1-light">
                                    Ulrike prioritises <span className="highlighted">efficiency</span> and creativity in her profession.<br />Despite her passion for her work, her long and stressful hours prevent her from <span className="highlighted">establishing a routine</span> for physical activity, healthy eating or personal self-care.
                                </p>
                            </section>

                            <section className="needs-frustrations">
                                <section className="needs">
                                    <h4 className="medium">Needs</h4>
                                    <ul>
                                        <li><p className="p1-light">Improved sleep quality and duration</p></li>
                                        <li><p className="p1-light">Better management of stress</p></li>
                                        <li><p className="p1-light">Increased energy levels in the morning</p></li>
                                        <li><p className="p1-light">A good sleeping routine</p></li>
                                    </ul>
                                </section>

                                <section className="frustrations">
                                    <h4 className="medium">Frustrations</h4>
                                    <ul>
                                        <li><p className="p1-light">Long working hours leading to fatigue</p></li>
                                        <li><p className="p1-light">Difficulty falling asleep and staying asleep</p></li>
                                        <li><p className="p1-light">Limited time for self-care and healthy habits</p></li>
                                        <li><p className="p1-light">Difficulties sticking to any beneficial routines</p></li>
                                        <li><p className="p1-light">Difficulties managing stress levels</p></li>
                                    </ul>
                                </section>
                            </section>
                        </section>
                    </section>


                </section>


            </section>
        </section>
    );
}

export default PersonaSection;
