import React from 'react';
import usability1 from '../images/usability_1.png'
import usability2 from '../images/usability_2.png'
import usability3 from '../images/usability_3.png'

function UsabilityTestsSection() {
    return (
        <section className="section usability-content dark">
            <h2 className="light section-headline right">Usability Tests</h2>

            <section className="container usability-container">
                <h4 className="medium subtitle right">
                    Based on insights from concept tests, we turned to Mid-Fi Wireframes for usability testing to refine the effectiveness of our digital twin and authentication concept. <br />

                    During these tests, we also gathered valuable feedback from our stakeholders to make sure we meet their goals with our work!
                </h4>

                <section className="usability-test">
                    <section className="dividing">
                        <p className="p1-light usability-text">
                            Educate users upfront about the // platform's offerings (Authentication, Marketplace, Insurance, etc.) and their possible benefits
                        </p>
                        <img src={usability1} alt="Mobile app's Explore screen displaying a user-friendly interface with category filters for Exercises, Meditations, Stories, Music, Noises, and Tips."></img>
                    </section>

                    <section className="personalized">
                        <p className="p1-light usability-text">
                            Pop-Up is too intrusive!
                            Better provide On-Screen Guidance for good Images
                        </p>
                        <img src={usability2} alt="Mobile app's Progress screen showing a graph of user's daily activity tracking and the ability to view details for motivation and routine improvement."></img>
                    </section>

                    <section className="dividing">
                        <p className="p1-light usability-text">
                            Where can user access item before it’s authenticity is confirmed?
                        </p>
                        <img src={usability3} alt="Mobile app's Progress screen showing a graph of user's daily activity tracking and the ability to view details for motivation and routine improvement."></img>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default UsabilityTestsSection;
