import React from 'react';

function MVPStatementSection() {
    return (
        <section className="section mvp dark">
            <h2 className="light section-headline right">MVP Statement</h2>

            <section className="mvp-container">
                <h4 className="light middle">Our app's primary goal is to deliver a <span className="highlighted">personalized</span> and motivating evening <span className="highlighted">routine</span> experience. It achieves this through personalized questions, generating a customizable daily habit schedule, and providing reminders for <span className="highlighted">staying on track</span>.<br />
                    Users can set goals, track progress, earn achievements, and enjoy activities such as exercise videos, guided meditations, calming music/noises, and audio stories to enhance their overall routine.</h4>
            </section>
        </section>
    );
}

export default MVPStatementSection;
