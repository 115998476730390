import React from 'react';

function IntroSection() {
    return (
        <section className="section dark introduction">
            <h2 className="light section-headline">Introduction</h2>

            <section className="intro-container">
                <section className="container">
                    <section className="intro-text">
                        <p className="p1-light">
                            Our Client is Retro//Vrs, an innovative French startup that revolutionizes the authentication and security of <span className="highlighted">pre-owned luxury goods</span> through <span className="highlighted">blockchain</span> technology and <span className="highlighted">3D digital twins</span>.
                        </p>
                        <p className="p1-light">
                            Counterfeits and pirated goods are projected to reach a staggering <span className="highlighted">USD 2.3 trillion</span> by 2022, with luxury items being particularly affected.
                        </p>
                        <p className="p1-light">
                            Authentication companies have emerged to combat this, but their complex processes may pose challenges for <span className="highlighted">non-tech-savvy</span> individuals. Simplifying authentication and building trust are vital, especially considering that millions lack basic digital skills.
                        </p>
                        <p className="p1-light">
                            Innovative solutions like Retro//VRS aim to provide an easy and reliable way to authenticate luxury items, ensuring accessibility for <span className="highlighted">all users</span>.
                        </p>
                    </section>
                    <section className="facts">
                        <ul>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Role: </span>UX / UI Designer
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Tools: </span>
                                    Figma, Figjam, Notion, Slack, Useberry, Zoom
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Duration: </span>10 Days
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Scope: </span>High-fidelity prototype design Retro//Fit feature
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Device: </span>iOS Mobile
                                </p>
                            </li>
                        </ul>
                    </section>
                </section>

                <h4 className="light"><span className="bold">&rarr; Our goal:</span> Improving the <span className="highlighted">digital twin</span> & <span class="highlighted">authentication</span> process!</h4>
            </section>
        </section>
    );
}

export default IntroSection;
