import React from 'react';

function EmpathyMapSection() {
    return (
        <section className="section empathy-content">
            <h2 className="light section-headline">Empathy Map</h2>

            <section className="container">
                <h4 className="medium subtitle">The empathy map will guide us towards a <span className="highlighted">user-centric</span> design and help us address specific challenges effectively.</h4>

                <section className="empathy-map">
                    <section className="empathy does">
                        <h4 className="medium">DOES</h4>
                        <ul>
                            <li><p className="p1-light">Tossing and turning</p></li>
                            <li><p className="p1-light">Watching movies / texting / social media</p></li>
                            <li><p className="p1-light">Counting sheeps</p></li>
                            <li><p className="p1-light">Counting hours of possible sleep</p></li>
                            <li><p className="p1-light">Take sleep aids</p></li>
                            <li><p className="p1-light">Read a book</p></li>
                            <li><p className="p1-light">Do relaxing activities</p></li>
                        </ul>
                    </section>

                    <section className="empathy hear">
                        <h4 className="medium">HEAR</h4>
                        <ul>
                            <li><p className="p1-light">Sounds of sheets</p></li>
                            <li><p className="p1-light">Ticking of the clock</p></li>
                            <li><p className="p1-light">Own heartbeat</p></li>
                            <li><p className="p1-light">Yawning in the morning</p></li>
                            <li><p className="p1-light">Music / Audiobooks etc.</p></li>
                            <li><p className="p1-light">Sounds of neighborhood</p></li>
                            <li><p className="p1-light">Wind and weather</p></li>
                        </ul>
                    </section>

                    <section className="empathy see">
                        <h4 className="medium">SEES</h4>
                        <ul>
                            <li><p className="p1-light">Dark room</p></li>
                            <li><p className="p1-light">Bed / Sheets / Pillows</p></li>
                            <li><p className="p1-light">Stressed work environment</p></li>
                            <li><p className="p1-light">Screens</p></li>
                            <li><p className="p1-light">Booksheets</p></li>
                        </ul>
                    </section>

                    <section className="empathy say">
                        <h4 className="medium">SAYS</h4>
                        <ul>
                            <li><p className="p1-light">"I can't sleep."</p></li>
                            <li><p className="p1-light">"I worry about tomorrow."</p></li>
                            <li><p className="p1-light">"I'm exhausted from tossing and turning."</p></li>
                            <li><p className="p1-light">"Getting up tomorrow will hard."</p></li>
                            <li><p className="p1-light">"Now it's only 5 hours left... 4... 3..."</p></li>
                            <li><p className="p1-light">"Why can't I just sleep as easy as my husband?"</p></li>
                        </ul>
                    </section>

                    <div className="user-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="343" height="343" viewBox="0 0 343 343" fill="none">
                            <path d="M273.225 297.833C270.513 248.558 235.5 202.625 171.5 202.625C107.5 202.625 72.4872 248.558 69.7751 297.833M222.625 115.375C222.625 143.611 199.736 166.5 171.5 166.5C143.264 166.5 120.375 143.611 120.375 115.375C120.375 87.1394 143.264 64.25 171.5 64.25C199.736 64.25 222.625 87.1394 222.625 115.375ZM335 171.5C335 261.799 261.799 335 171.5 335C81.2014 335 8 261.799 8 171.5C8 81.2014 81.2014 8 171.5 8C261.799 8 335 81.2014 335 171.5Z" stroke="#F6F0F0" strokeWidth="15" strokeLinecap="round" />
                        </svg>
                    </div>
                </section>

                <section className="pain-gain">
                    <section className="empathy pain">
                        <h4 className="medium"><span className="highlighted">&#8722;</span> Pain</h4>
                        <ul>
                            <li><p className="p1-light">Fears of morning fatigue and its impact on health</p></li>
                            <li><p className="p1-light">Anxiety over reduced concentration and productivity</p></li>
                            <li><p className="p1-light">Concerns about lacking motivation due to tiredness</p></li>
                        </ul>
                    </section>
                    <section className="empathy gain">
                        <h4 className="medium"><span className="highlighted">&#43;</span> Gain</h4>
                        <ul>
                            <li><p className="p1-light">Enhanced sleep quality</p></li>
                            <li><p className="p1-light">Increased daytime energy levels</p></li>
                            <li><p className="p1-light">Improved overall health</p></li>
                            <li><p className="p1-light">Heightened focus and productivity</p></li>
                            <li><p className="p1-light">Elevated mood and reduced stress</p></li>
                        </ul>
                    </section>
                </section>


            </section>


        </section>
    );
}

export default EmpathyMapSection;
