import React from 'react';

function QuantitativeSection() {
    return (
        <section className="section qualitative dark">
            <h2 className="light section-headline right">Qualitative Research</h2>

            <section className="qualitative-container">
                <section className="qualitative-content">
                    <section className="subtitle-container">
                        <h4 className="medium right subtitle">
                            Now we want to take a look at people's behaviors and <span className="highlighted">pain points</span> in detail, so we conducted interviews with 5 people:
                        </h4>
                    </section>

                    <section className="container middle">
                        <section className="qualitative-quotes">
                            <section className="quotes">
                                <p className="p1-light">
                                    "Apps like tracking daily routine, sleep behavior, and eating habits also analyzing the data can be usefull."
                                </p>
                                <p className="p1-light">- Kim</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "Listening to comfortable background sounds and be allowed to create our own mix can be a effective."
                                </p>
                                <p className="p1-light">- Kim</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "I sometimes eat snacks late into the night which effects my sleep."
                                </p>
                                <p className="p1-light">- Sinan</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "Eating before going to bed, too much screen time and alcohol or caffeine."
                                </p>
                                <p className="p1-light">- Sinan</p>
                            </section>

                            <section className="quotes">
                                <p className="p1-light">
                                    "I often find it tough to stay on track with my routine."
                                </p>
                                <p className="p1-light">- Birte</p>
                            </section>
                            
                            <section className="quotes">
                                <p className="p1-light">
                                    "I listen to audiobooks or guided meditations almost every night."
                                </p>
                                <p className="p1-light">- Jaqueline</p>
                            </section>
                        </section>
                    </section>


                </section>


            </section>
        </section>
    );
}

export default QuantitativeSection;
