import React from 'react';
import '../../../css/Casestudy.css'

function Ikea() {
  return (
    <section id="ikea" className="section">
      <h2 className="light section-headline">Ikea</h2>

      
    </section>
  );
}

export default Ikea;
