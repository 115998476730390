import React from 'react';

function MoscowMethodSection() {
    return (
        <section className="section moscow-content">
            <h2 className="light section-headline">Moscow Method</h2>

            <section className="container moscow">
                <h4 className="medium subtitle">The next step was to efficiently <span className="highlighted">prioritize</span> the app's features based on their importance and impact.</h4>

                <section className="moscow-method">
                    <section className="must">
                        <h4 className="medium">Must Have</h4>
                        <ul>
                            <li><p className="p1-light">A daily habit schedule to keep track of the evening routine</p></li>
                            <li><p className="p1-light">A function to easily edit the schedule</p></li>
                            <li><p className="p1-light">Various reminders to stay on track with the routine</p></li>
                            <li><p className="p1-light">Dark design to reduce screen brightness and strain on the eyes</p></li>
                            <li><p className="p1-light">Progress tracker and data visualization</p></li>
                        </ul>

                    </section>

                    <section className="could">
                        <h4 className="medium">Could Have</h4>
                        <ul>
                            <li><p className="p1-light">Dim light reminder for winding down before sleep</p></li>
                            <li><p className="p1-light">Voice control for hands-free usage</p></li>
                            <li><p className="p1-light">Integrated alarms for waking up</p></li>
                            <li><p className="p1-light">Sleep analysis to monitor sleep patterns</p></li>
                            <li><p className="p1-light">Sleep phase alarm for waking during the lightest sleep phase</p></li>
                            <li><p className="p1-light">Notes for tracking thoughts or feelings</p></li>
                            <li><p className="p1-light">Connection with wearables</p></li>
                            <li><p className="p1-light">Background music during app usage</p></li>
                            <li><p className="p1-light">Community function for challenges with friends</p></li>
                            <li><p className="p1-light">Tips and information relevant to the task at hand</p></li>
                        </ul>

                    </section>

                    <section className="should">
                        <h4 className="medium">Should Have</h4>
                        <ul>
                            <li><p className="p1-light">Personalized questions at the start to set customized habits</p></li>
                            <li><p className="p1-light">Setting Goals</p></li>
                            <li><p className="p1-light">Progress tracking with scores and achievements</p></li>
                            <li><p className="p1-light">Different activities like Exercise videos, Guided meditations, Music or background noises, and Short Stories</p></li>
                        </ul>

                    </section>

                    <section className="wont">
                        <h4 className="medium">Won't Have</h4>
                        <ul>
                            <li><p className="p1-light">Social media integration</p></li>
                            <li><p className="p1-light">In-app purchases for additional features</p></li>
                            <li><p className="p1-light">Advanced gamification elements for challenges and rewards</p></li>
                            <li><p className="p1-light">Direct integration with smart home devices</p></li>
                            <li><p className="p1-light">Customizable app themes beyond dark design</p></li>
                            <li><p className="p1-light">Detailed calorie or nutrition tracking</p></li>
                        </ul>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default MoscowMethodSection;
