import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/Footer.css';

function Footer() {
    const navigate = useNavigate(); // Zum Programmatisch navigieren

    const handleLogoClick = () => {
        // Navigiert zur Home-Seite und scrollt zum Anfang
        navigate('/');
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <footer>
            <section className="copyright-box">
                <section className="logo" onClick={handleLogoClick}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" height="3rem" viewBox="0 0 40 40" fill="none">
                        <path fillRule="evenodd" clipRule="evenodd" d="M0 21.0278V0.0699759C0.534732 0.026241 1.08699 0 1.63049 0C12.0095 0 20.3636 8.52832 20.3636 18.9722V39.93C19.8289 39.9738 19.2766 40 18.7331 40C8.36285 40 0 31.4717 0 21.0278ZM18.7331 38.5455C18.7916 38.5455 18.8503 38.5451 18.9091 38.5445V18.9722C18.9091 9.3108 11.1855 1.45455 1.63049 1.45455C1.57203 1.45455 1.51337 1.45488 1.45455 1.45553V21.0278C1.45455 30.6888 9.18642 38.5455 18.7331 38.5455Z" fill="#EE2E46" />
                        <path fillRule="evenodd" clipRule="evenodd" d="M24.7273 18.1336V40C25.2197 39.8451 25.7048 39.6727 26.1818 39.4833C34.238 36.286 40 28.2764 40 18.9555V18.022C40 14.2474 36.798 10.9091 32.697 10.9091C28.596 10.9091 24.7273 14.2474 24.7273 18.1336ZM32.697 12.3636C31.0329 12.3636 29.3786 13.0466 28.1426 14.1493C26.9063 15.2524 26.1818 16.6857 26.1818 18.1336V37.9092C33.4114 34.791 38.5455 27.4618 38.5455 18.9555V18.022C38.5455 15.0578 36.0017 12.3636 32.697 12.3636Z" fill="#EE2E46" />
                    </svg>
                </section>

                <section className="copyright">
                    <p className="p2-light">Built with love, sweat and tears <span className="heart">&#9829;</span></p>

                    <p className="p2-light">
                        <span>©</span>
                        <span> 2024 Belgüzar Kal - <a href="/impressum" className="impressum">Impressum</a></span>
                    </p>
                </section>
            </section>
        </footer>
    );
}

export default Footer;
