import React from 'react';
import logo from '../images/logo.png';
import '../css/About.css';

function About() {
    return (
        <section id="about" className="section">
            <div>
                <h2 className="light section-headline">About</h2>
            </div>
            <article className="about-container">
                <section className="about-content">
                    <section className="about-headline">
                        <h3 className="light text">
                            <span className="highlighted">Hey, I'm Belli</span>,
                            merging the worlds of design and development.
                        </h3>
                        <section className="about-img-mobile">
                            <figure className="img">
                                <img src={logo} alt="Neon red outline of abstract geometric shapes" />
                            </figure>
                        </section>
                    </section>

                    <section className="about-text">
                        <p className="p1-light text">
                            I combine my creative background in UX/UI and Fashion Design with a passion for digital technology. The synthesis of aesthetics and functionality captivates me. I place a strong emphasis on Accessibility, ensuring that my designs are inclusive and accessible to all.
                        </p>

                        <p className="p1-light text">
                            I'm the kind of person who can't resist tinkering, experimenting, and exploring. Once I'm interested in something, I immerse myself until I've absorbed it entirely. I thrive on embracing contrasting ideas, analyzing patterns in my daily life, and abstracting insights into my designs.
                        </p>

                        <p className="p1-light text">
                            Taking a holistic approach to my work and the world around me is what drives me.
                        </p>

                        <p className="p1-light text">
                            I love Harry Potter and listening to Retro Wave. 🪐✨
                        </p>
                    </section>

                    <section className="button-container">
                        <a id="cv-header" href={`${process.env.PUBLIC_URL}/cv.pdf`} className="secondary small button" target="_blank" rel="noopener noreferrer">DOWNLOAD CV</a>
                    </section>
                </section>

                <section className="about-img">
                    <figure className="img">
                        <img src={logo} alt="Neon red outline of abstract geometric shapes" />
                    </figure>
                </section>
            </article>
        </section>
    );
}
export default About;

