import React from 'react';
import '../css/Profession.css';

function Profession() {
    return (
        <section id="profession" className="section">
            <h2 className="light section-headline right">Profession</h2>
            <article className="profession-container">
                <section id="dev" className="profession">
                    <figure className="profession-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <g filter="url(#filter0_dddd_284_1042)">
                                <path
                                    d="M34.6665 22.4395L36.9285 24.4752C38.9852 26.3263 40.0136 27.2518 40.0136 28.4395C40.0136 29.6271 38.9852 30.5526 36.9285 32.4037L34.6665 34.4395"
                                    stroke="#F6F0F0" strokeLinecap="round" />
                                <path d="M30.6489 18.6667L27.9999 28.5532L25.3508 38.4396" stroke="#F6F0F0"
                                    strokeLinecap="round" />
                                <path
                                    d="M21.3334 22.4395L19.0714 24.4752C17.0147 26.3263 15.9863 27.2518 15.9863 28.4395C15.9863 29.6271 17.0147 30.5526 19.0714 32.4037L21.3334 34.4395"
                                    stroke="#F6F0F0" strokeLinecap="round" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_284_1042" x="-13" y="-13" width="82" height="82"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_284_1042" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_284_1042"
                                        result="effect2_dropShadow_284_1042" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_284_1042"
                                        result="effect3_dropShadow_284_1042" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_284_1042"
                                        result="effect4_dropShadow_284_1042" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_284_1042"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </figure>

                    <section className="profession-div">
                        <label htmlFor="dev" className="l1-medium">Web Development</label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="51" viewBox="0 0 130 75" fill="none">
                            <g filter="url(#filter0_dddd_283_839)">
                                <line x1="25" y1="25.5" x2="105" y2="25.5" stroke="#F6F0F0" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_283_839" x="0" y="0" width="130" height="51" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_283_839" result="effect2_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_283_839" result="effect3_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_283_839" result="effect4_dropShadow_283_839" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_283_839" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        <p className="p2-light profession-text">
                            I turn lines of code into digital experiences that blend form and function with seamless precision. Performance meets responsive design, ready to engage users on any device.
                        </p>
                    </section>
                </section>

                <section id="ux" className="profession">
                    <figure className="profession-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <g filter="url(#filter0_dddd_284_834)">
                                <circle cx="28" cy="20" r="4" stroke="#F6F0F0" />
                                <path
                                    d="M38.6668 36.8C38.6668 39.2 33.8912 40 28.0002 40C22.1091 40 17.3335 39.2 17.3335 36.8C17.3335 32.8235 22.1091 28 28.0002 28C33.8912 28 38.6668 32.8235 38.6668 36.8Z"
                                    stroke="#F6F0F0" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_284_834" x="-13" y="-13" width="82" height="82"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_284_834" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_284_834"
                                        result="effect2_dropShadow_284_834" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_284_834"
                                        result="effect3_dropShadow_284_834" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_284_834"
                                        result="effect4_dropShadow_284_834" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_284_834" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </figure>

                    <section className="profession-div">
                        <label htmlFor="ux" className="l1-medium">User Experience</label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="51" viewBox="0 0 130 75" fill="none">
                            <g filter="url(#filter0_dddd_283_839)">
                                <line x1="25" y1="25.5" x2="105" y2="25.5" stroke="#F6F0F0" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_283_839" x="0" y="0" width="130" height="51" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_283_839" result="effect2_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_283_839" result="effect3_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_283_839" result="effect4_dropShadow_283_839" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_283_839" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        <p className="p2-light profession-text">
                            UX is the narrative of the user journey. I design with purpose, building each interaction on research and refined by testing to captivate and connect.
                        </p>
                    </section>
                </section>

                <section id="ui" className="profession">
                    <figure className="profession-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="56" viewBox="0 0 56 56" fill="none">
                            <g filter="url(#filter0_dddd_283_1052)">
                                <path
                                    d="M30.5171 19.5505L31.5057 18.5619C33.1438 16.9238 35.7997 16.9238 37.4378 18.5619C39.0759 20.2 39.0759 22.8558 37.4378 24.4939L36.4491 25.4826M30.5171 19.5505C30.5171 19.5505 30.6407 21.6515 32.4944 23.5052C34.3482 25.359 36.4491 25.4826 36.4491 25.4826M30.5171 19.5505L21.4278 28.6399C20.8121 29.2555 20.5043 29.5633 20.2396 29.9027C19.9273 30.3031 19.6596 30.7363 19.4411 31.1947C19.2559 31.5832 19.1183 31.9962 18.8429 32.8222L17.9615 35.4666L17.6763 36.3222M36.4491 25.4826L27.3598 34.5719C26.7441 35.1875 26.4363 35.4954 26.0969 35.7601C25.6965 36.0724 25.2633 36.3401 24.805 36.5585C24.4164 36.7437 24.0034 36.8814 23.1775 37.1567L20.533 38.0382L19.6775 38.3234M19.6775 38.3234L18.8219 38.6086C18.4154 38.7441 17.9673 38.6383 17.6643 38.3353C17.3614 38.0323 17.2556 37.5842 17.3911 37.1777L17.6763 36.3222M19.6775 38.3234L17.6763 36.3222"
                                    stroke="#F6F0F0" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_283_1052" x="-13" y="-13" width="82" height="82"
                                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_283_1052" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_283_1052"
                                        result="effect2_dropShadow_283_1052" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_283_1052"
                                        result="effect3_dropShadow_283_1052" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_283_1052"
                                        result="effect4_dropShadow_283_1052" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_283_1052"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </figure>

                    <section className="profession-div">
                        <label htmlFor="ui" className="l1-medium">User Interface</label>
                        <svg xmlns="http://www.w3.org/2000/svg" width="130" height="51" viewBox="0 0 130 75" fill="none">
                            <g filter="url(#filter0_dddd_283_839)">
                                <line x1="25" y1="25.5" x2="105" y2="25.5" stroke="#F6F0F0" />
                            </g>
                            <defs>
                                <filter id="filter0_dddd_283_839" x="0" y="0" width="130" height="51" filterUnits="userSpaceOnUse"
                                    colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="12.5" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.9" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.8 0" />
                                    <feBlend mode="normal" in2="effect1_dropShadow_283_839" result="effect2_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="6.4" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 0.1 0" />
                                    <feBlend mode="normal" in2="effect2_dropShadow_283_839" result="effect3_dropShadow_283_839" />
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                        result="hardAlpha" />
                                    <feOffset />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feColorMatrix type="matrix"
                                        values="0 0 0 0 0.282353 0 0 0 0 0.898039 0 0 0 0 0.760784 0 0 0 1 0" />
                                    <feBlend mode="normal" in2="effect3_dropShadow_283_839" result="effect4_dropShadow_283_839" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_283_839" result="shape" />
                                </filter>
                            </defs>
                        </svg>
                        <p className="p2-light profession-text">
                            Pixel by pixel, I craft UIs where every detail is intentional. My designs prioritize clarity and ease, creating intuitive spaces that guide and delight.
                        </p>
                    </section>
                </section>
            </article>
        </section>
    );
}
export default Profession;
