import React from 'react';
import '../../../css/Casestudy.css'

import TitleSection from './components/TitleSection';
import IntroSection from './components/IntroSection';
import PrototypeSection from './components/PrototypeSection';


function Taski() {
  return (
    <article id="taski" className="casestudy">
      <TitleSection />
      <IntroSection />
      <PrototypeSection />
    </article>
  );
}

export default Taski;

