import React, { useState, useEffect } from 'react';
import projectsData from '../data/projectsData';
import Project from './Project';

const shuffleArray = (array) => {
  let newArray = [...array];
  for (let i = newArray.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

const ProjectsDisplay = ({ category }) => {
  const [showMore, setShowMore] = useState(false);
  const [shuffledProjects, setShuffledProjects] = useState([]);

  useEffect(() => {
    if (category === 'all') {
      setShuffledProjects(shuffleArray([].concat(...Object.values(projectsData))));
    }
  }, [category]);

  let projectsToShow = category === 'all' ? shuffledProjects : projectsData[category];

  return (
    <>
      <section className="projects-1">
        {projectsToShow.slice(0, 4).map((project, index) => (
          <Project key={index} project={{ ...project, projectClass: getProjectClass(index, false) }} />
        ))}
      </section>
      {showMore && (
        <section className="projects-2">
          {projectsToShow.slice(4).map((project, index) => (
            <Project key={index + 4} project={{ ...project, projectClass: 'other-projects' }} />
          ))}
        </section>
      )}
      <div className="btn-container">
        <button className="secondary small see-more" onClick={() => setShowMore(!showMore)}>
        {showMore ? <span>Show Less</span> : <span>Show More</span> }
        </button>
      </div>
    </>
  );
};

// Überarbeitete Funktion zur korrekten Zuweisung der neuen Klassennamen
const getProjectClass = (index) => {
  switch (index) {
    case 0:
      return 'project-1';
    case 1:
      return 'project-2';
    case 2:
      return 'project-3';
    case 3:
      return 'project-4';
    default:
      return 'other-projects'; // Standardklasse, falls benötigt
  }
};

export default ProjectsDisplay;
