import React from 'react';

function CompetitiveSection() {
    return (
        <section className="section competitive dark">
            <h2 className="light section-headline">Competitive Analysis</h2>

            <section className="competitive-container">
                <section className="container">
                    <section className="competitive-content">
                        <h4 className="medium subtitle">
                            Let's start by conducting a competitive analysis to assess Retro//Vrs' biggest business competitors in terms of authentication!
                        </h4>
                        <section className="container">
                            <table>
                                <tbody>
                                    <tr>
                                        <th className="features"><h5 className="light">Authentication Method</h5></th>
                                        <th><h5 className="light">Entrupy</h5></th>
                                        <th><h5 className="light">Authentifier.com</h5></th>
                                        <th><h5 className="light">Dataweave</h5></th>
                                        <th><h5 className="light">Zekos Authentication</h5></th>
                                    </tr>
                                    <tr>
                                        <td className="features">AI-powered microscopic analysis of material</td>
                                        <td>Over 60 luxury brands</td>
                                        <td>Wide range of luxury brands</td>
                                        <td>Majority of luxury brands</td>
                                        <td>Wide range of luxury brands</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Supported Brands</td>
                                        <td>Within minutes</td>
                                        <td>24 hours</td>
                                        <td>Within minutes</td>
                                        <td>24 hours</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Turnaround Time</td>
                                        <td>Available for download with Entrupy Pro account</td>
                                        <td>Available for purchase</td>
                                        <td>Available for purchase</td>
                                        <td>Provided with authentication service</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Authentication Certificate</td>
                                        <td>$10–15 per item or monthly subscription fee</td>
                                        <td>Varies depending on item and service level</td>
                                        <td>Varies depending on item and service level</td>
                                        <td>Varies depending on item and service level</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Cost per Authentication</td>
                                        <td>Yes, with Entrupy Pro account</td>
                                        <td>No</td>
                                        <td>No</td>
                                        <td>No</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Mobile App</td>
                                        <td>Yes, with detailed instructions</td>
                                        <td>No</td>
                                        <td>Yes, with clear instructions</td>
                                        <td>No</td>
                                    </tr>
                                    <tr>
                                        <td className="features">User-Friendly Interface</td>
                                        <td>Global</td>
                                        <td>Global</td>
                                        <td>Global</td>
                                        <td>US only</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Authentication Coverage</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <tbody>
                                    <tr>
                                        <th className="features"><h5 className="light">Features</h5></th>
                                        <th><h5 className="light">Vestiaire Collective</h5></th>
                                        <th><h5 className="light">The RealReal</h5></th>
                                        <th><h5 className="light">Vinted</h5></th>
                                        <th><h5 className="light">StockX</h5></th>
                                        <th><h5 className="light">Retro//Vrs</h5></th>
                                    </tr>
                                    <tr>
                                        <td className="features">Website</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">App</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Sell preloved Items</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Buy preloved Items</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Digital Twin Technology</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">Verify Items on your own</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Guidance</td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">How it works</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Luxury Assets</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Sell/buy digital Asset for metaverse</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                    <tr>
                                        <td className="features">Blockchain technology</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">AI technology</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">Profile option</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">Certifications</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">Real experts</td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr>
                                        <td className="features">Like, Comment, Share</td>
                                        <td></td>
                                        <td></td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                        <td className="checkmark">&#10003;</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </section>
                </section>


            </section>
        </section>
    );
}

export default CompetitiveSection;
