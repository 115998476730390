import React from 'react';
import title from '../images/title.png';

function TitleSection() {
  return (
    <section id="title" className="section">
      <section className="title-container">
        <section className="title-content">
          <section className="title-headlines">
            <h1 className="bold tagline">Taski</h1>
            <h3 className="light">Your ultimate Task Management Tool</h3>
          </section>

          <section className="project-categories">
            <p className="project-categorie">CRUD Project</p>
            <p className="project-categorie">Laravel</p>
            <p className="project-categorie">MySQL</p>
          </section>
        </section>

        <img src={title} alt="Iphone Mockup mit Abbildung der Retrovrs-App"></img>
      </section>
    </section>
  );
}

export default TitleSection;
