import React from 'react';

function PrototypeSection() {
    return (
        <section className="section prototype-content">
            <h2 className="light section-headline">Final Prototype</h2>

            <section className="container prototype-container">
                <section className="prototype-video">
                    <iframe width="370" height="700" src="https://www.youtube.com/embed/OhHVUPvIe1s?si=KZhS9jbCaErmb17a" 
                    title="YouTube video player" frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
                    referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
                    </iframe>
                </section>
            </section>
        </section>
    );
}

export default PrototypeSection;
