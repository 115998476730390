import React from 'react';
import moodboard from '../images/moodboard.png'
import styletile from '../images/styletile.png'

function MoodStyleSection() {
    return (
        <section className="section mood-style-content dark">
            <h2 className="light section-headline right">Moodboard & Style Tile</h2>

            <section className="tiles">
                <section className="container moodboard-container">
                    <img src={moodboard} alt=""></img>
                </section>
                <section className="container styletile-container">
                    <img src={styletile} alt=""></img>
                </section>
            </section>
        </section>
    );
}

export default MoodStyleSection;
