import React from 'react';
import concept1 from '../images/concept_1.png'
import concept2 from '../images/concept_2.png'

function ConceptTestsSection() {
    return (
        <section className="section concept-content">
            <h2 className="light section-headline">Concept Tests</h2>

            <section className="container concept-container">
                <h4 className="medium subtitle">With our Lo-Fi Wireframes we performed the first testings to validate the <span className="highlighted">effectiveness</span> of our concept.</h4>

                <section className="concept-test">
                    <section className="dividing">
                        <p className="p1-light concept-text">
                            Dividing the questions into two screens would offer a clearer and
                            user-friendly way to specify individual needs.
                        </p>
                        <img src={concept1} alt="Mobile app interface for setting work hours with weekday options and customizable time slots."></img>
                    </section>

                    <section className="personalized">
                        <p className="p1-light concept-text">
                            Dividing the questions into two screens would offer a clearer and
                            user-friendly way to specify individual needs.
                        </p>
                        <img src={concept2} alt="Evening routine planner in a health app displaying the calendar date, scheduled activities like Exercise and Meditation, and a button to add new entries."></img>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default ConceptTestsSection;
