import React from 'react';
import emoji1 from '../images/emoji1.png';
import emoji2 from '../images/emoji2.png';
import emoji3 from '../images/emoji3.png';
import emoji4 from '../images/emoji4.png';
import emoji5 from '../images/emoji5.png';
import emoji6 from '../images/emoji6.png';

function UserJourneyMapSection() {
    return (
        <section className="section ujm-content">
            <h2 className="light section-headline">User Journey Map</h2>

            <section className="container">

                <section className="ujm">
                    <section className="phase1 box">
                        <h6 className="bold daytime">PHASE 1</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Fiona finds an old luxury handbag in her closet that she wants to sell and make some money from it.
                            </p>
                            <img src={emoji1} className="emoji" alt="Emoji showing an idea"></img>
                            <h6 className="medium quote">"Wow, I didn't see this one in years! I should sell it!"</h6>
                        </section>
                    </section>

                    <section className="phase2 box">
                        <h6 className="bold daytime">PHASE 2</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                The bag was a gift, and she's unsure of its authenticity. She begins searching online, spending hours browsing websites and forums for information.
                            </p>
                            <img src={emoji2} className="emoji" alt="Emoji with a thinking face"></img>
                            <h6 className="medium quote">"But I don't have a receipt... I wonder if it's authentic..."</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW simplify the process of researching and verifying the authenticity of luxury items?</li>
                        </ul>
                    </section>

                    <section className="phase3 box">
                        <h6 className="bold daytime">PHASE 3</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                She discovers authentication companies, but their complex and time-consuming in-person or bag-sending process doesn't suit her.
                            </p>
                            <img src={emoji3} className="emoji" alt="Emoji with an unimpressed face"></img>
                            <h6 className="medium quote">"Meh... the authentication process seems like a hassle... There must be an easier way!"</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW make the authentication process more accessible and convenient for customers?</li>
                        </ul>
                    </section>

                    <section className="phase4 box">
                        <h6 className="bold daytime">PHASE 4</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Fiona discovers Retro//VRS, an app offering easy and reliable authentication using innovative tech like photogrammetry, AI, and blockchain.
                            </p>
                            <img src={emoji4} className="emoji" alt="Emoji with a smiling face"></img>
                            <h6 className="medium quote">"Nice! This app looks promising and modern, it could be the solution I need!"</h6>
                        </section>
                    </section>

                    <section className="phase5 box">
                        <h6 className="bold daytime">PHASE 5</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Despite watching the tutorial for Retro//Fit, Fiona, not tech-savvy, remains uncertain about taking the right photos and using the app properly.
                            </p>
                            <img src={emoji5} className="emoji" alt="Emoji with a confused face"></img>
                            <h6 className="medium quote">"Is this right? I don't understand what I'm doing..."</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW provide clearer instructions and guidance to help users accurately use the Retro//Fit feature?</li>
                        </ul>
                    </section>

                    <section className="phase6 box">
                        <h6 className="bold daytime">PHASE 6</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Post-authentication, Fiona receives a certificate, but doubts its reliability due to the process being too easy and lacking sufficient guidance or feedback.
                            </p>
                            <img src={emoji6} className="emoji" alt="Emoji with a skeptical face"></img>
                            <h6 className="medium quote">"Hmm... I don't trust in this certificate. How does this whole technology even work?"</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW improve the reliability and trustworthiness of the authentication certificate provided by the app?</li>
                        </ul>
                    </section>
                </section>

            </section>
        </section>
    );
}

export default UserJourneyMapSection;
