import React from 'react';

function IntroSection() {
    return (
        <section className="section dark introduction">
            <h2 className="light section-headline">Introduction</h2>

            <section className="intro-container">
                <section className="container">
                    <section className="intro-text">
                        <h4 className="medium subtitle">A good day always starts the <span className="highlighted">night</span> before.</h4>
                        <p className="p1-light">
                            Sleep is crucial to good health and crucial to good sleep is effective preparation beforehand. The 2-3 hours before going to bed will make or break our sleep quality.
                        </p>
                        <p className="p1-light">
                            Together with my fellow Ironhacker Sinem, we participated in a competition to create an app for the wellness market.
                            We chose to focus on the sleep improvement segment due to the importance of quality sleep and its impact on overall well-being.
                        </p>
                    </section>
                    <section className="facts">
                        <ul>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Role: </span>UX / UI Designer
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Tools: </span>Figma, Figjam, Notion, Slack, Useberry, Google Forms, Zoom
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Duration: </span>10 Days
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Scope: </span>High-fidelity prototype design
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Device: </span>iOS Mobile
                                </p>
                            </li>
                        </ul>
                    </section>
                </section>

                <h3 className="light"><span className="bold">&rarr; Our goal:</span> Help users establish <span className="highlighted">healthier evening routines</span> to improve their sleep quality!</h3>
            </section>
        </section>
    );
}

export default IntroSection;
