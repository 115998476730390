import React, { useState } from 'react';
import FilterButtons from './FilterButtons';
import ProjectsDisplay from './ProjectsDisplay';

import '../css/Work.css';

function Work() {
    // Zustand für die aktuell ausgewählte Kategorie
    const [selectedCategory, setSelectedCategory] = useState('all');

    // Funktion, um den Zustand bei Klick auf einen Filter-Button zu ändern
    const handleFilterChange = (category) => {
        setSelectedCategory(category);
    };

    return (
        <section id="work" className="section">
        <h2 className="light section-headline">Projects</h2>
        {/* Übergebe selectedCategory und setCategory an FilterButtons */}
        <FilterButtons selectedCategory={selectedCategory} setCategory={handleFilterChange} />
        {/* ProjectsDisplay zeigt Projekte basierend auf der ausgewählten Kategorie an */}
        <ProjectsDisplay category={selectedCategory} />
      </section>
    );
}

export default Work;
