import React from 'react';
import '../css/Hero.css';

function Hero() {
    return (
        <section id="home" className="section">
            <div>
                <h1 className="bold tagline">Creating for <br /> the mind & eye</h1>
                <p className="name">Belli Kal</p>
            </div>
        </section>
    );
}
export default Hero;