import React from 'react';
import title from '../images/title.png';

function TitleSection() {
  return (
    <section id="title" className="section">
      <section className="title-container">
        <section className="title-content">
          <section className="title-headlines">
            <h1 className="bold tagline">Retrovrs</h1>
            <h3 className="light">Secure your Fashion Assets</h3>
          </section>

          <section className="project-categories">
            <p className="project-categorie">iOS Mobile Design</p>
            <p className="project-categorie">Feature Design</p>
            <p className="project-categorie">Luxury Asset Hub</p>
          </section>
        </section>

        <img src={title} alt="Iphone Mockup mit Abbildung der Retrovrs-App"></img>
      </section>
    </section>
  );
}

export default TitleSection;
