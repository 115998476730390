import React from 'react';
import '../css/Skills.css';

function Skills() {
    return (

        <section id="skills" className="section">
            <h2 className="light section-headline right">Skillset</h2>
            <section className="skills-items">
                {/* Hardskills */}
                <article className="pill hardskill">
                    <p className="all">responsive design</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">user research</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">usability</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">wireframing</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">information architecture</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">Prototyping</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">user-centered design</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">web accessibility</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">typography</p>
                </article>
                
                <article className="pill hardskill">
                    <p className="all">html5</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">css3</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">scss</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">javascript</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">php</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">laravel</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">jquery</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">react.js</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">git</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">bootstrap</p>
                </article>
                <article className="pill hardskill">
                    <p className="all">tailwind</p>
                </article>

                {/* Softskills */}
                <article className="pill softskill">
                    <p className="all">communication</p>
                </article>
                <article className="pill softskill">
                    <p className="all">problem-solving</p>
                </article>
                <article className="pill softskill">
                    <p className="all">time-management</p>
                </article>
                <article className="pill softskill">
                    <p className="all">teamwork</p>
                </article>
                <article className="pill softskill">
                    <p className="all">adaptability</p>
                </article>
                <article className="pill softskill">
                    <p className="all">creativity</p>
                </article>
                <article className="pill softskill">
                    <p className="all">attention-to-detail</p>
                </article>
                <article className="pill softskill">
                    <p className="all">empathy</p>
                </article>
                <article className="pill softskill">
                    <p className="all">continuous-learning</p>
                </article>
                <article className="pill softskill">
                    <p className="all">critical-thinking</p>
                </article>
                <article className="pill softskill">
                    <p className="all">growth-oriented</p>
                </article>
            </section>
        </section>

    );
}
export default Skills;