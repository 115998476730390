import React from 'react';

function IntroSection() {
    return (
        <section className="section dark introduction">
            <h2 className="light section-headline">Introduction</h2>

            <section className="intro-container">
                <section className="container">
                    <section className="intro-text">
                        <p className="p1-light">
                            Taski is a simple task management application developed with Laravel. It allows users to create, edit, and delete tasks assigned to various categories such as Household, Personal, Work, or Family. Tasks can be set with due dates and commented on. The dashboard also allows viewing tasks from other users. Taski is my first Laravel project and serves as a foundation for future expansions and improvements.                        </p>
                    </section>
                    <section className="facts">
                        <ul>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Role: </span>Frontend & Backend Developer
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Used: </span>
                                    VSCode, XAMPP, Apache, MySQL, Bootstrap, Laravel, PHP
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Scope: </span>CRUD project using Laravel
                                </p>
                            </li>
                            <li>
                                <p className="p1-light">
                                    <span className="p1-bold">Device: </span>Responsive Web & Mobile Application
                                </p>
                            </li>
                        </ul>
                    </section>
                </section>
                <section className="facts main-features">
                    <h4 className="medium">Main Features</h4>
                    <ul>
                        <li>
                            <p className="p1-light">
                                Create, edit, and delete tasks.
                            </p>
                        </li>
                        <li>
                            <p className="p1-light">
                                Assign tasks to categories (Household, Personal, Work, Family).
                            </p>
                        </li>
                        <li>
                            <p className="p1-light">
                                Set due dates for tasks.
                            </p>
                        </li>
                        <li>
                            <p className="p1-light">
                                Comment on tasks.
                            </p>
                        </li>
                        <li>
                            <p className="p1-light">
                                Dashboard to view tasks from other users.
                            </p>
                        </li>
                    </ul>
                </section>
            </section>
        </section>
    );
}

export default IntroSection;
