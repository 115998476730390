import React from 'react';
import vcasleepcycle from '../images/vca_sleepcycle.png'
import vcapillow from '../images/vca_pillow.png'
import vcabettersleep from '../images/vca_bettersleep.png'

function VCASection() {
    return (
        <section className="section vca-content">
            <h2 className="light section-headline">Visual Competitor Analysis</h2>

            <section className="container vca-container">
                <h4 className="medium subtitle">To identify common <span className="highlighted">design patterns</span> and aesthetics, we conducted a thorough analysis of our competitors looks. This process generated valuable insights that strongly influenced our design <span className="highlighted">decisions</span> for the UI of the App.</h4>

                <section className="vca-tiles">

                    <img src={vcasleepcycle} alt=""></img>

                    <img src={vcapillow} alt=""></img>

                    <img src={vcabettersleep} alt=""></img>

                </section>

                <section className="insights-container">
                    <h3 className="bold">Insights:</h3>
                    <section className="insights">
                        <section className="insight">
                            <h5 className="bold">All:</h5>
                            <ul>
                                <li><p className="p1-light"><span className="highlighted">They all use dark color schemes</span></p></li>
                            </ul>
                        </section>
                        <section className="insight">
                            <h5 className="bold">Pillow & Sleep Cycle:</h5>
                            <ul>
                                <li><p className="p1-light">More analyzing look</p></li>
                                <li><p className="p1-light">More real photos than illustrations</p></li>
                                <li><p className="p1-light"><span className="highlighted">Some vibrant colors</span></p></li>
                            </ul>
                        </section>
                        <section className="insight">
                            <h5 className="bold">Better Sleep:</h5>
                            <ul>
                                <li><p className="p1-light">Less saturated colors</p></li>
                                <li><p className="p1-light"><span className="highlighted">Lots of illustrations</span></p></li>
                                <li><p className="p1-light"><span className="highlighted">Cute Style</span></p></li>
                            </ul>
                        </section>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default VCASection;
