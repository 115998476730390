import React from 'react';
import persona from '../images/persona.png';
import emoji1 from '../images/emoji1.png';
import emoji2 from '../images/emoji2.png';
import emoji3 from '../images/emoji3.png';
import emoji4 from '../images/emoji4.png';
import emoji5 from '../images/emoji5.png';

function UserJourneyMapSection() {
    return (
        <section className="section ujm-content">
            <h2 className="light section-headline">User Journey Map</h2>

            <section className="container">

                <section className="ujm">
                    <section className="persona-intro box">
                        <h5 className="bold">Unguided Ulrike</h5>
                        <img src={persona} alt="Portrait of a smiling young woman with a messy bun"></img>
                        <p className="p2-light">
                            Ulrike, frequently indulges in unhealthy habits when stressed from work. She distracts herself with TV, eats unhealthy and doesn't exercise or move a lot. She struggles to relax, often relying on her phone to fall asleep.
                        </p>
                        <p className="p2-light"><span className="highlighted">This pattern of behaviour regularly results in late nights and difficulty sleeping and overall exhaustion.</span>
                        </p>
                    </section>

                    <section className="after-work box">
                            <h6 className="bold daytime">After Work</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Ulrike had a very hard day at work. She comes home and resolves to do something for her well-being and then to go to bed early.
                            </p>
                            <img src={emoji1} className="emoji" alt="A not so happy looking emoji"></img>
                            <h6 className="medium quote">"I should really take care of myself today!"</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW remind her the basic daily needs?</li>
                            <li>HMW help her to develop new healthy habits?</li>
                        </ul>
                    </section>

                    <section className="evening box">
                            <h6 className="bold daytime">Evening</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                She wants to prepare food for dinner. But she's too stressed, so she gives in to the temptation to order food.
                            </p>
                            <img src={emoji2} className="emoji" alt="A not so happy looking emoji"></img>
                            <h6 className="medium quote">"Okay, I just don't feel like cooking."</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW help her to eat healthy via offering nutritious selection of meals and maintain a healthy weight?</li>
                        </ul>
                    </section>

                    <section className="late-evening box">
                            <h6 className="bold daytime">Late evening</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                She throws herself on the sofa and starts eating in front of the TV.
                            </p>
                            <img src={emoji3} className="emoji" alt="A not so happy looking emoji"></img>
                            <h6 className="medium quote">"Just a little time to relax, and then I will take a nice long walk!"</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW help her to relief the energy in her body via some exercises?</li>
                        </ul>
                    </section>

                    <section className="late-night box">
                            <h6 className="bold daytime">Late Night</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                She got hooked on a new exciting TV series and now has cravings for sweets again, so she gets some.
                            </p>
                            <img src={emoji4} className="emoji" alt="A not so happy looking emoji"></img>
                            <h6 className="medium quote">"Oops, what time is it? But I want to finish this episode... Where are the snacks!"</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW help her to keep hunger cravings at bay?</li>
                            <li>HMW help her to be aware of screen time limits?</li>
                        </ul>
                    </section>

                    <section className="midnight box">
                            <h6 className="bold daytime">Midnight</h6>
                        <section className="box-content">
                            <p className="p2-light">
                                Late at night, she struggles to sleep, feeling bloated, with racing thoughts and a restless body. Spending hours scrolling on her phone, she regrets not sticking to her earlier good intentions.
                            </p>
                            <img src={emoji5} className="emoji" alt="A not so happy looking emoji"></img>
                            <h6 className="medium quote">"Argh.. Stupid me! Always the same..."</h6>
                        </section>
                        <ul className="opportunities">
                            <li>HMW help her fall asleep easier?</li>
                            <li>HMW help her have a high quality rest during the night?</li>
                            <li>HMW help her to relieve stress?</li>
                            <li>HMW give her some alternatives to screen usage?</li>
                        </ul>
                    </section>
                </section>
            </section>
        </section>
    );
}

export default UserJourneyMapSection;
