import React from 'react';
import screen1 from '../images/screen1.png';
import screen2 from '../images/screen2.png';

function HeuristicSection() {
    return (
        <section className="section heuristic-content">
            <h2 className="light section-headline">Heuristic Analysis</h2>

            <section className="container heuristic">
                <h4 className="medium subtitle">We note, addressing the clarity of the overall process will enhance the user experience and alleviate user uncertainties!</h4>

                <section className="heuristic-container">
                    <div className="feedback-section">
                        <div className="feedback-block">
                            <img src={screen1} alt="Screenshot of the app interface for capturing a handbag" className="screenshot" />
                            <div className="feedback-texts">
                                <h3 className="medium feedback-title">"Am I doing it right?" 😕</h3>
                                <p className="p1-light feedback-text">Process of creating digital twin is not clear</p>
                                <p className="p1-light feedback-text">No feedback for good or bad photos</p>
                            </div>
                        </div>

                        <div className="feedback-block">
                            <img src={screen2} alt="Screenshot of the app interface showing loading progress" className="screenshot" />
                            <div className="feedback-texts">
                                <h3 className="medium feedback-title">"What is going on?" 😖</h3>
                                <p className="p1-light feedback-text">Bigger loading screen to convey sense of progress</p>
                                <p className="p1-light feedback-text">More prominent progress steps for improved clarity</p>
                            </div>
                        </div>
                    </div>

                </section>
            </section>
        </section>
    );
}

export default HeuristicSection;
